.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.side-floater {
  top: 60px;
  position: sticky !important;
  background-color: #EFEFEF;
  padding: 20px 30px
}

.bg-grey {
  background-color: #EFEFEF;
}

.bg-shade {
  background-color: #e0e8ff33;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
	}
			 
	.link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
  }

	.contant_box_404{ margin-top:-50px;}


  .nav-link.active {
    background-color: #dc3545 !important;
    color: #FFFFFF !important;
    border: medium none;
    /* border-radius: 0; */
  }
  .nav-link {
    color: #282828;
    padding-top: 4px;
    padding-bottom: 4px;
    /* border-radius: 0; */
  }

  #nav-daily-tab {
    border: solid 2px #EFEFEF !important;
  }
  
  #nav-hourly-tab {
    border: solid 2px #EFEFEF !important;
  }

  .search-bar {
    padding-top: 300px !important;
  } 